<template>
  <v-app-bar
    id="app-toolbar"
    app
    color="primary"
    dark
    elevate-on-scroll
    extension-height="40"
    short
  >
    <v-container class="pa-0 d-flex">
      <common-app-logo route="event-detail" />

      <v-spacer />

      <v-toolbar-items>
        <!-- <v-btn
          :aria-label="$t('Vuetify.AppToolbar.documentation')"
          exact
          min-width="48"
          text
          to="getting-started/quick-start"
        >
          <base-nav-text class="hidden-sm-and-down">Vuetify.AppToolbar.documentation</base-nav-text>

          <v-icon class="hidden-md-and-up">mdi-speedometer</v-icon>
        </v-btn>-->

        <!-- <base-locales-menu /> -->
        <core-avatar v-if="isLoggedin"></core-avatar>
      </v-toolbar-items>
    </v-container>
  </v-app-bar>
</template>

<script>
import { get } from 'vuex-pathify';
export default {
  name: 'BlankAppBar',
  computed: {
    isLoggedin: get('auth/isLoggedin')
  }
};
</script>
